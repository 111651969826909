import { shallowRef } from 'vue';
import { AdminAuth } from '@nsftx/seven-client-sdk';

export const keycloakInstance = shallowRef(null);

let initialized = false;
let accessToken = '';

export const initializeKeyloak = async () =>
  new Promise((resolve) => {
    keycloakInstance.value = new AdminAuth({
      environment: appEnvironment,
      debug: true,
      events: {
        onTokenUpdated: (token) => {
          accessToken = token;

          if (!initialized) {
            initialized = true;
            resolve();
          }
        },
      },
    });

    keycloakInstance.value.initialize();
  });

export const getAccessToken = () => accessToken;
