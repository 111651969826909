import axios from 'axios';
import JSONBigInt from 'json-bigint';
import { isEmpty } from 'lodash-es';
import { coreURLConstruct } from './formatter';
import { getAccessToken } from '@/services/auth.js';

const http = axios.create();

const JSONBigIntNative = JSONBigInt({ storeAsString: true });

/**
 * Converts numeric values to BigInt recursively within the given data structure.
 *
 * @param {any} data - The data to convert to BigInt.
 * @returns {any} - The converted data with numeric values replaced by BigInt values.
 */
export function convertToBigInt(data) {
  return JSONBigIntNative.parse(data);
}

// Temporary here.
function validateUrl(url) {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * Request interceptor:
 *   Prevents default behavior parsing the response with JSON.parse.
 */
http.interceptors.request.use((request) => {
  request.transformResponse = [(data) => data];
  return request;
});

/**
 * Response interceptor:
 *  Parses response with custom parsing function.
 */
http.interceptors.response.use((response) => {
  if (isEmpty(response.data)) return response;

  response.data = convertToBigInt(response.data);
  return response;
});

/**
 * Default headers for PATCH method.
 */
http.defaults.headers.patch['Content-Type'] = 'application/merge-patch+json';

/**
 * Set Authorization header from interceptor.
 */
http.interceptors.request.use((config) => {
  if (!validateUrl(config.url)) config.url = `${coreURLConstruct()}${config.url}`;

  config.headers.Authorization = `Bearer ${getAccessToken()}`;
  return config;
});

export default http;
