import './assets/main.css';
import '@nsftx/seven-client-sdk/admin-ui';

import { createApp } from 'vue';
import App from './App.vue';

import { registerPlugins } from '@/plugins';
import { initializeKeyloak } from '@/services/auth.js';

BigInt.prototype.toJSON = () => this.toString();

(async () => {
  await initializeKeyloak();

  const app = createApp(App);
  registerPlugins(app);

  app.mount('#app');
})();
